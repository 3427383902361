<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>

        <!-- 介绍创始人&企业文化 -->
        <div class="jieshao">
            <div class="jieshaobox">
                <div class="jieshao-title">
                    <h1>关于我们</h1>
                    <p class="jieshaohr-solid"></p>
                </div>
                <div class="edu">
                    <!-- <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>企业简介</p>
                    </div> -->
                    <div class="educationimg">
                        <img src="../assets/images/zyyai/公司简介2.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>创始人简介</p>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/zyyai/创始人简介2.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                 <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>企业文化</p>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/zyyai/企业使命愿景.png" alt="">
                        <p class="hezhao">团队合照</p>
                        <img src="../assets/images/zyyai/合照.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>知识产权</p>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/zyyai/知识产权.png" alt="">
                    </div>
                    <!-- 证件照 -->
                    <div class="zhengjian">
                        <img class="zjimg1" src="../assets/images/zyyai/专利证书.jpg" alt="">
                        <img class="zjimg1"  src="../assets/images/zyyai/众易用增值电信证.jpg" alt="">
                        <img class="zjimg1"  src="../assets/images/zyyai/8.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>联系我们</p>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/about/联系我们22.png" alt="">
                        <img src="../assets/images/about/联系我们3.png" alt="">
                    </div>
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue"
export default {
    name:"ZyyaiView",
    components:{
      HeaderNav
    },
    data() {
        return{

        }
    },
}
</script>

<style scoped>
.body{
width: 100%;
height: 100%;   
}

/* 介绍创始人&企业文化 */
.jieshao{
  width: 100%;
  height: 5500px;
  position: relative;
  margin: 0 auto 0 auto;
  /* background-color: rgba(238, 118, 55, 0.1); */
}
.jieshaobox{
  width: 1000px;
  height: 5500px;
  position: absolute;
  /* background-color: rgb(121, 77, 20); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.jieshaohr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  /* margin-left: 100px; */
}
.jieshao-title{
  text-align: center;
}
.jieshao-text{
    width: 1000px;
    height: 1000px;
    /* background-color: cadetblue; */
    margin-left: 100px;
}
.jieshao-text h2{
  text-align: center;
}
.jieshao-text p{
  text-indent: 2em;
}
.jieshaoimg img{
    width: 320px;
    margin-left: 120px;
}
.jieshaoimg2 img{
    margin-top: 10px;
    width: 760px;
    margin-left: 120px;
}
.jsp{
    line-height: 2;
}
/* 创始人和企业文化 */
.educationimg img{
    width: 1000px;
    margin-top: 30px;
}
.edu{
    margin-bottom: 100px;
}

/* 编号 */
.education-txt{
    width: 300px;
    height: 50px;
    margin-top: 30px;
    display: flex;
}
.education-txt img{
    width: 50px;
    height: 50px;
}
.education-txt p{
    font-size: 30px;
    line-height: 1px;
    color: rgb(238, 118, 55);
    margin-left: 10px;
    font-weight: 700;
}
/* 证件 */
.zhengjian{
    width: 1000px;
    height: 350px;
    margin-top: 20px;
    display: flex;
}
.zjimg1{
    width: 250px;
    height: 300px;
    margin-left: 62.5px;
}
/* 虚线 dashed */
.hr-solid2 {
  width: 1000px;
  border: 0;
  border-top: 3px dashed rgb(238, 118, 55);
}
.hezhao{
    color: rgb(238, 118, 55);
    font-size: 22px;
    font-weight: 700;
}
</style>
