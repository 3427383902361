<template>
  <div class="body">
    <!-- 导航nav -->
    <HeaderNav/>
    <!-- 轮播 -->
    <BannerView />

    <!-- aboutus -->
    <div class="about">
      <div class="about-box">
        <!-- 7个图标 -->
        <div class="iconimg">
          <div class="iconbox" @click="toaizhiya()">
            <img  class="hover-zoom"  src="../assets/images/home/AI 智牙.png" alt="">
            <p>AI 智牙</p>
          </div>
          <div class="iconbox" @click="toaijigntong()">
            <img class="hover-zoom"  src="../assets/images/home/劲童AI.png" alt="">
            <p>AI 劲童</p>
          </div>
          <div class="iconbox" @click="toaigaoguangpu()">
            <img class="hover-zoom"  src="../assets/images/home/AI 高光谱.png" alt="">
            <p> AI 高光谱</p>
          </div>
          <div class="iconbox" @click="toaishuzi()">
            <img class="hover-zoom"  src="../assets/images/home/AI 电力数字孪生.png" alt="">
            <p>AI 电力数字孪生</p>
          </div>
          <div class="iconbox" @click="toaizhaopai()">
            <img class="hover-zoom"  src="../assets/images/home/招牌 AI.png" alt="">
            <p>AI 招牌检测</p>
          </div>
          <div class="iconbox" @click="toaishuzir()">
            <img class="hover-zoom"  src="../assets/images/home/AI 数字人.png" alt="">
            <p>AI 数字人</p>
          </div>
          <div class="iconbox" @click="toaixitong()">
            <img class="hover-zoom"  src="../assets/images/home/AI+系统.png" alt="">
            <p>AI + 系统</p>
          </div>
          
        </div>
        <div class="about-title">
          <h1>关于众易用智能科技</h1>
          <p class="hr-solid"></p>
        </div>
        <div class="about-text">
          <p>广州众易用智能科技有限公司，简称众易用AI，定位是“ AI 行业 + 应用解决方案专家”。背靠中山大学深厚的人文底蕴和技术力量，技术涵盖生成式人工智能、三维仿真、人机交互、智能感知、数字孪生等领域。</p>
          <p>团队创始于2016年，从事三维仿真交互设计。2021年企业转型升级，专注于人工智能应用。目前具有发明专利12项、软件著作权12项、商标3项。</p>
          <p>公司创始人黄志青博士参加2024联合国粮农组织的农创客大赛荣获铜奖，是唯一获奖的中国选手。</p>
        </div>
        <div class="about-img">
        </div>
      </div>
      
      
    </div>
    <!-- chuangshi -->
    <div class="chuangshi">
      <div class="chuangshi-box">
        <div class="chuangshi-text">
          <div>
            <h2>创始人介绍</h2>
            <p class="hr-solid2"></p>
          </div>
          <div class="chuangshi-txt">
            <img src="../assets/images/home/caret-right.png" alt="">
            <p>黄志青博士</p>
          </div>
        </div>
        <div class="chuangshi-img">
          <img src="../assets/images/home/创始人简介最新2.png" alt="">
        </div>
        
      </div>
    </div>

    <!-- 4企业 -->
    <div class="qiyejingshen">
      <div class="jingshen-box"> 
           <div class="about-title">
            <h1>企业使命与愿景</h1>
            <p class="hr-solid"></p>
          </div>
        <img src="../assets/images/home/企业使命愿景.png" alt="">
      </div>
    </div>



  </div>
</template>

<script  >
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import DetailView from "@/views/DetailView.vue"
// import {mapMutations} from "vuex"
import HeaderNav from "@/components/HeaderNav.vue"
import BannerView from "@/components/BannerView.vue"
// import ListView from "@/components/home/ListView.vue"
// import { MaterialAPI } from "@/request/api.js"
// import { Pagination } from "element-ui";

export default {
  name: "HomeView",
  components: {
    // "el-pagination": Pagination,
    HeaderNav,
    BannerView,
    // ListView,
  },
  data() {
    return {
        modelData:[],
        reference_pic:[],
        pageindex: 1,
        pagelines: 30,
        totalcount: 0, 
    };
  },
  
  created() {
    // this.chatModel()
    
    // this.handleCurrentChange()
    

    
  },

  methods: {
    // 主页7图标跳转
    //   navigateToPageB(targetHeight) {
    //   this.$router.push({
    //     path: '/applicationai',
    //     query: { height: targetHeight },
    //   });
    // },
     //ai智牙
      toaizhiya() {
      this.$router.push({
        path: "/applicationai",
        query: {},
      });
      // window.scrollTo({
      //   top:0,
      //   });
    },

    //ai劲童
      toaijigntong() {
      this.$router.push({
        path: "/applicationai",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },
        //ai高光谱
      toaigaoguangpu() {
      this.$router.push({
        path: "/applicationai",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },
            //ai电力数字孪生
      toaishuzi() {
      this.$router.push({
        path: "/applicationai",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },
            //ai招牌检测
      toaizhaopai() {
      this.$router.push({
        path: "/applicationai",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },
                //ai数字人
      toaishuzir() {
      this.$router.push({
        path: "/productservice",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },
                //ai系统
      toaixitong() {
      this.$router.push({
        path: "/productservice",
        query: {},
      });
      window.scrollTo({
        top: 0,
        });
    },

    // ...mapMutations({
    //   chanisshowLogin:"showLogin/chanisshowLogin"
    // }),
    // scrollToTop() {
    //     window.scrollTo({
    //     top: 0,
    //     // behavior: 'smooth' 
    //     }); 
    //   },
    // handleCurrentChange(newpageindex){
    //   this.pageindex = newpageindex;
    //   this.chatModel();
    //   this.scrollToTop();
    //     },
    
    // 旧
    // //跳转资讯页面
    // toInformation() {
    //   let InformationView = this.$router.resolve({
    //     // 跳转到的页面路径
    //     path: "/information",
    //     // 传递的参数集合
    //     query: {},
    //   });
    //   window.open(InformationView.href, "_blank");
    // },
    //    //跳转AIGC教育
    //   goaigcjy2() {
    //   this.$router.push({
    //     // 跳转到的页面路径
    //     path: "/education",
    //     // 传递的参数集合
    //     query: {},
    //   });
    //   window.scrollTo({
    //     top: 0,
    //     // behavior: 'smooth' 
    //     });
    // },
    //    //跳转AIGC业务
    //   goaiyw() {
    //   this.$router.push({
    //     // 跳转到的页面路径
    //     path: "/business",
    //     // 传递的参数集合
    //     query: {},
    //   });
    //   window.scrollTo({
    //     top: 0,
    //     // behavior: 'smooth' 
    //     });
    // },

    msg() {
      this.$message({
        message: "暂未开放，敬请期待",
        type: "warning",
        duration: 700,
      });
    },
    dan() {
      this.$message({
        message:"请先登录",
        type: "error",
        duration: 700,
      });
    },
    
  },
};
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
}

//about
.about{
  width: 100%;
  height: 500px;
  // background-color: pink;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  // margin-left: 50px;
}
.about-box{
  width: 1000px;
  height: 500px;
  position: absolute;
  // background-color: antiquewhite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-title h1{
  text-align: center;
}
.about-h2{
  text-align: center;
}
.about-text{
  line-height: 2;
}
.about-text p{
  text-indent: 2em;
}
.about-img video{
  width: 1000px;
  margin-top: 50px;
  // margin-left: 0px;
}
// 4
.qiyejingshen{
  //background-color:rgba(238, 118, 55, 0.1);
  width: 100%;
  height: 1000px;
  position: relative;
  margin: 0 auto;
}
.jingshen-box{
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 1000px;
  
}
.jingshen-box img{
  width: 1000px;
  margin-top: 70px;
}


//chuangshi
.hr-solid2 {
  width: 130px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  // margin-left: 150px;
}
.chuangshi{
  width: 100%;
  height: 550px;
  position: relative;
  margin: 0 auto;
  // background-color: antiquewhite;
}
.chuangshi-box{
  width: 1000px;
  height: 550px;
  position: absolute;
  // background-color: rgb(121, 77, 20);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.chuangshi-txt{
  display: flex;
  
}
.chuangshi-txt p{
  margin-top: 10px;
}
.chuangshi-txt img{
  width: 40px;
  height: 40px;
}
.chuangshi-img img{
  width: 800px;
  margin-left: 70px;
  margin-top: 30px;
}
.chuangshi-text{
  margin-top: 30px;
}
// 顶图7个图标
.iconimg{
  display: flex;
  margin-bottom: 50px;
}
.iconbox{
  margin-right: 25px;
}
.iconbox img{
  width: 121px;
  height: 121px; 
  cursor: pointer;
}
.iconbox p{
  text-align: center;
  cursor: pointer;
}
.hover-zoom{
  transition: transform 0.3s ease
}
.hover-zoom:hover {
  transform: scale(1.1); /* 将图片放大到原来的1.1倍 */
}
</style>
