var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('HeaderNav'),_c('div',{staticClass:"education"},[_c('div',{staticClass:"education-box"},[_vm._m(0),_c('div',{staticClass:"edu"},[_vm._m(1),_c('div',{staticClass:"appvideo"},[_c('video',{attrs:{"src":require("../assets/images/aigceducation/AI高光谱助力精准农业.mp4"),"loop":"loop","controls":"controls","muted":"muted"},domProps:{"muted":true}})]),_vm._m(2)]),_c('p',{staticClass:"hr-solid2"}),_c('div',{staticClass:"edu"},[_vm._m(3),_c('div',{staticClass:"appvideo"},[_c('video',{attrs:{"src":require("../assets/images/aigceducation/数字人牙医AI智牙系统.mp4"),"loop":"loop","controls":"controls","muted":"muted"},domProps:{"muted":true}})]),_vm._m(4)]),_c('p',{staticClass:"hr-solid2"}),_c('div',{staticClass:"edu"},[_vm._m(5),_c('div',{staticClass:"appvideo"},[_c('video',{attrs:{"src":require("../assets/images/aigceducation/劲童AI_全国首款幼儿素质培养AI智能体.mp4"),"loop":"loop","controls":"controls","muted":"muted"},domProps:{"muted":true}})]),_vm._m(6)]),_c('p',{staticClass:"hr-solid2"}),_c('div',{staticClass:"edu"},[_vm._m(7),_c('div',{staticClass:"appvideo"},[_c('video',{attrs:{"src":require("../assets/images/aigceducation/AI招牌检测.mp4"),"loop":"loop","controls":"controls","muted":"muted"},domProps:{"muted":true}})]),_vm._m(8)]),_c('p',{staticClass:"hr-solid2"}),_c('div',{staticClass:"edu"},[_vm._m(9),_c('div',{staticClass:"appvideo"},[_c('video',{attrs:{"src":require("../assets/images/aigceducation/AI+电力数字孪生.mp4"),"loop":"loop","controls":"controls","muted":"muted"},domProps:{"muted":true}})]),_vm._m(10)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-title"},[_c('h1',[_vm._v("AI + 行业应用解决方案")]),_c('p',{staticClass:"hr-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-txt"},[_c('img',{attrs:{"src":require("../assets/images/home/caret-right.png"),"alt":""}}),_c('p',[_vm._v("AI 高光谱 助力精准农业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educationimg"},[_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI高光谱22.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI高光谱11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-txt"},[_c('img',{attrs:{"src":require("../assets/images/home/caret-right.png"),"alt":""}}),_c('p',[_vm._v("数字人牙医 + AI 智牙系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educationimg"},[_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI智牙22.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI智牙3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-txt"},[_c('img',{attrs:{"src":require("../assets/images/home/caret-right.png"),"alt":""}}),_c('p',[_vm._v("劲童 AI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educationimg"},[_c('img',{attrs:{"src":require("../assets/images/aigceducation/劲童AI2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-txt"},[_c('img',{attrs:{"src":require("../assets/images/home/caret-right.png"),"alt":""}}),_c('p',[_vm._v("AI + 招牌检测")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educationimg"},[_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI招牌检测.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-txt"},[_c('img',{attrs:{"src":require("../assets/images/home/caret-right.png"),"alt":""}}),_c('p',[_vm._v("AI + 电力数字孪生")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educationimg"},[_c('img',{attrs:{"src":require("../assets/images/aigceducation/AI电力孪生.png"),"alt":""}})])
}]

export { render, staticRenderFns }