<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>
        <!-- aboutus -->
    <div class="about">
      <div class="about-box">
        <div class="about-title">
          <h1>成功案例</h1>
          <p class="hr-solid"></p>
        </div>
          <div class="edu">
                    <!-- <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>创始人简介</p>
                    </div> -->
                    <div class="educationimg">
                        <img src="../assets/images/about/成功案例1.png" alt="">
                        <p class="hr-solid2"></p>
                        <img src="../assets/images/about/成功案例2.png" alt="">
                    </div>
         </div>

      </div>
    </div>


    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default {
    name:"AboutView",
    components:{
        HeaderNav
    },
    data() {
        return{

        }
    },
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  height: 100%;
}

//about
.about{
  width: 100%;
  height: 1500px;
  // background-color: pink;
  position: relative;
  margin: 0 auto;
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
//   margin-left: 150px;
}
.about-box{
  width: 1000px;
  height: 1500px;
  position: absolute;
  // background-color: antiquewhite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-title{
  text-align: center;
}
.about-h2{
  text-align: center;
}
.about-text{
  line-height: 2;
}
.about-text p{
  text-indent: 2em;
}
.about-img video{
  width: 1000px;
  margin-top: 50px;
}
/* 联系我们 */
.educationimg img{
    width: 1000px;
    margin-bottom: 80px;
}
.edu{
    margin-bottom: 100px;
}

/* 编号 */
.education-txt{
    width: 300px;
    height: 50px;
    margin-top: 30px;
    display: flex;
}
.education-txt img{
    width: 50px;
    height: 50px;
}
.education-txt p{
    font-size: 30px;
    line-height: 1px;
    color: rgb(238, 118, 55);
    margin-left: 10px;
    font-weight: 700;
}
/* 虚线 dashed */
.hr-solid2 {
  width: 1000px;
  border: 0;
  border-top: 3px dashed rgb(238, 118, 55);
}
</style>
