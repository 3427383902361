<template>
    <div class="body" >
        <!-- 导航nav -->
        <HeaderNav/>

        <!-- AI + 行业应用解决方案案例 ref="scrollContainer"-->
        <div class="education"  >
            <div class="education-box">
                <div class="education-title">
                    <h1>AI + 行业应用解决方案</h1>
                    <p class="hr-solid"></p>
                </div>
                
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI 高光谱 助力精准农业</p>
                    </div>
                    <!-- 视频 自动播放：autoplay="autoplay"-->
                    <div class="appvideo">
                        <video src="../assets/images/aigceducation/AI高光谱助力精准农业.mp4" loop="loop" controls="controls" muted="muted" ></video>
                    </div>
                    <!-- 图片 -->
                    <div class="educationimg">
                        <img src="../assets/images/aigceducation/AI高光谱22.png" alt="">
                        <img src="../assets/images/aigceducation/AI高光谱11.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                 <div class="edu">
                    <div class="education-txt">
                    <img  src="../assets/images/home/caret-right.png" alt="">
                    <p>数字人牙医 + AI 智牙系统</p>
                    </div>
                    <!-- 视频 自动播放：autoplay="autoplay"-->
                    <div class="appvideo">
                        <video src="../assets/images/aigceducation/数字人牙医AI智牙系统.mp4" loop="loop" controls="controls" muted="muted" ></video>
                    </div>
                    <!-- 图片 -->
                    <div class="educationimg">
                        <img src="../assets/images/aigceducation/AI智牙22.png" alt="">
                        <img src="../assets/images/aigceducation/AI智牙3.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>劲童 AI</p>
                    </div>
                    <!-- 视频 自动播放：autoplay="autoplay"-->
                    <div class="appvideo">
                        <video src="../assets/images/aigceducation/劲童AI_全国首款幼儿素质培养AI智能体.mp4" loop="loop" controls="controls" muted="muted" ></video>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/aigceducation/劲童AI2.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI + 招牌检测</p>
                    </div>
                    <!-- 视频 自动播放：autoplay="autoplay"-->
                    <div class="appvideo">
                        <video src="../assets/images/aigceducation/AI招牌检测.mp4" loop="loop" controls="controls" muted="muted" ></video>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/aigceducation/AI招牌检测.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI + 电力数字孪生</p>
                    </div>
                    <!-- 视频 自动播放：autoplay="autoplay"-->
                    <div class="appvideo">
                        <video src="../assets/images/aigceducation/AI+电力数字孪生.mp4" loop="loop" controls="controls" muted="muted" ></video>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/aigceducation/AI电力孪生.png" alt="">
                    </div>
                </div>


                

                

                
            </div>
        </div>

    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default {
    name: "EducationView",
    components:{
        HeaderNav,
    },
    data(){
        return{

        };
    },
    mounted(){
    // const scrollPosition = parseInt(this.$route.query.scrollTo, 10);
    // if (!isNaN(scrollPosition)) {
    //   this.$nextTick(() => {
    //     this.$refs.scrollContainer.scrollTop = scrollPosition;
    //   });
    // }
    // this.scrollToTargetHeight();

    // console.log('PageB mounted');
    // const targetHeight = parseInt(this.$route.query.height, 10);
    // console.log('Target height:', targetHeight);
    // if (!isNaN(targetHeight)) {
    //  this.scrollToHeight(targetHeight);
    // } else {
    //     console.error('Invalid height value:', this.$route.query.height);
    // }
    },

    watch: {
    // '$route.query.height': 'scrollToTargetHeight',
    },
    methods:{
    //   scrollToTargetHeight() {
    //   const targetHeight = parseInt(this.$route.query.height, 10);
    //   if (!isNaN(targetHeight)) {
    //     this.$nextTick(() => {
    //       this.$refs.scrollContainer.scrollTop = targetHeight;
    //     });
    //   }
    // },
  
    }

}
   
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
  
}
/* AI + 行业应用解决方案案例 */
.education{
  width: 100%;
  height: 7000px;
  /* background-color: rgba(238, 118, 55, 0.1); */
  position: relative;
  margin: 0 auto;
  
}
.education-box{
  width: 1000px;
  height: 7000px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  /* margin-left: 300px; */
}
/* 虚线 dashed */
.hr-solid2 {
  width: 1000px;
  border: 0;
  border-top: 3px dashed rgb(238, 118, 55);
}
/* .education-title{
    margin-top: 100px;
} */
.education-title h1{
    text-align: center;
}
.educationimg img{
    width: 1000px;
}
.edu{
    margin-bottom: 100px;
}

/* 编号 */
.education-txt{
    width: 500px;
    height: 50px;
    margin-top: 30px;
    display: flex;
}
.education-txt img{
    width: 50px;
    height: 50px;
}
.education-txt p{
    font-size: 30px;
    line-height: 1px;
    color: rgb(238, 118, 55);
    margin-left: 10px;
    font-weight: 700;
}
/* 视频样式 */
.appvideo video{
  width: 800px;
  height: 500px;
  margin-top: 50px;
  margin-left: 100px;
}
/* 图标定位 */
/* .aiyayi{
   overflow-y: scroll; 
} */
</style>
