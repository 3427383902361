<template>
    <div class="body">
        <!-- 导航nav -->
        <HeaderNav/>

          <!-- AI + 行业应用解决方案案例 -->
        <div class="education">
            <div class="education-box">
                <div class="education-title">
                    <h1>产品与服务</h1>
                    <p class="hr-solid"></p>
                </div>
                <div class="edu">
                    <div class="education-txt">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p> “ AI+ ” 概念概述</p>
                    </div>
                    <div class="educationimg">
                        <img src="../assets/images/business/产品与服务11.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt2">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI + 服务版块</p>
                    </div>
                    <div class="educationimg2">
                        <img src="../assets/images/business/产品与服务22.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                 <div class="edu">
                    <div class="education-txt2">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI 互动数字人</p>
                    </div>
                    <!-- <div class="educationimg3">
                        
                    </div> -->
                    <div class="educationimg2">
                        <img src="../assets/images/business/产品与服务34.png" alt="">

                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt2">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI 数字人视频</p>
                    </div>

                    <div class="educationimg2">
                        <img src="../assets/images/business/产品与服务3.png" alt="">
                        <img src="../assets/images/business/产品与服务3333.png" alt="">
                    </div>
                </div>
                <p class="hr-solid2"></p>
                <div class="edu">
                    <div class="education-txt2">
                    <img src="../assets/images/home/caret-right.png" alt="">
                    <p>AI + 行业系统</p>
                    </div>
                    <div class="educationimg2">
                        <img src="../assets/images/business/产品与服务4.png" alt="">
                    </div>
                </div>

  
            </div>
        </div>


    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue"

export default{
    name:"BusinessView",
    components:{
        HeaderNav
    },
    data(){
        return{

        }
    },
    methods:{
        //跳转数字人页面
      goDigitalhuman() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/digitalhuman",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        }); 
    }, 
     //跳恒沙数字馆页面
    toHengshaguan() {
      window.open("http://show.hengshadigit.com/home","_blank")
      
    },
     //跳转数字文博页面
      goDigitalhuicif() {
      this.$router.push({
        // 跳转到的页面路径
        path: "/digitalhuicif",
        // 传递的参数集合
        query: {},
      });
      window.scrollTo({
        top: 0,
        // behavior: 'smooth' 
        });
    }, 
    }
}
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
}
/* 产品与服务 */
.education{
  width: 100%;
  height: 4800px;
  /* background-color: rgba(238, 118, 55, 0.1); */
  position: relative;
  margin: 0 auto;
}
.education-box{
  width: 1000px;
  height: 4800px;
  position: absolute;
  /* background-color: pink; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hr-solid {
  width: 1000px;
  border: 0;
  border-top: 3px solid rgb(238, 118, 55);
  /* margin-left: 300px; */
}
/* 虚线 dashed */
.hr-solid2 {
  width: 1000px;
  border: 0;
  border-top: 3px dashed rgb(238, 118, 55);
}
/* .education-title{
    margin-top: 100px;
} */
.education-title h1{
    text-align: center;
}
.educationimg img{
    width: 1000px;
    /* margin-top: 10px; */
}
.edu{
    margin-bottom: 100px;
}

/* 编号 */
.education-txt{
    width: 500px;
    height: 50px;
    margin-top: 30px;
    display: flex;
}
.education-txt img{
    width: 50px;
    height: 50px;
}
.education-txt p{
    font-size: 30px;
    line-height: 1px;
    color: rgb(238, 118, 55);
    margin-left: 10px;
    font-weight: 700;
}
.education-txt2{
    width: 500px;
    height: 50px;
    margin-top: 30px;
    display: flex;
}
.education-txt2 img{
    width: 50px;
    height: 50px;
}
.education-txt2 p{
    font-size: 30px;
    line-height: 1px;
    color: rgb(238, 118, 55);
    margin-left: 10px;
    font-weight: 700;

}
.educationimg2 img{
    width: 1000px;
    margin-top: 30px;
}
.educationimg3{
    display: flex;
}
.educationimg3 img{
    width: 500px;
    margin-top: 30px;
}
</style>
