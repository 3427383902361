<template>
  <div class="body">
      <!-- 轮播 -->
    <div class="logo-banner">
      <el-carousel :interval="5000" arrow="always" height="480px">
        <el-carousel-item v-for="item in banner" :key="item.id"> 
          <img :src="item.idView" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>

export default {
  name:"BannerView",
    data(){
        return{
            banner:[
              // {id:0,idView:require('../assets/images/banner/banner1.jpg')},
              // {id:1,idView:require('../assets/images/banner/banner2.png')},
              {id:0,idView:require('../assets/images/banner/banner5.png')},
              {id:1,idView:require('../assets/images/banner/banner7-1.png')},
              {id:2,idView:require('../assets/images/banner/banner4最新.png')},
              {id:3,idView:require('../assets/images/banner/banner6-1.jpg')},
              ],

            // 浏览器宽度
            screenWidth :0
        }
    },
    methods:{
      setSize:function () {
                // 通过浏览器宽度(图片宽度)计算高度
                this.bannerHeight = 480 / 1920 * this.screenWidth;
              },
            },
          mounted() {
              // 首次加载时,需要调用一次
              this.screenWidth =  window.innerWidth;
              this.setSize();
              // 窗口大小发生改变时,调用一次
              window.onresize = () =>{
              this.screenWidth =  window.innerWidth;
              this.setSize();
            }

    },
}
</script>

<style scoped>
.body {
  width: 100%;
  background-color: #333333;
}

/* banner */
.logo-banner {
  width: 100%;
  height: 480px;
  background-color: #333333;
}

/* .el-carousel__item:nth-child(1) {
  background-image: url(../assets/images/banner/banner1.jpg);
}
.el-carousel__item:nth-child(2) {
  background-image: url(../assets/images/banner/banner2.png);
} */
.el-carousel__item:nth-child(3) {
  background-image: url(../assets/images/banner/banner5.png);
}
.el-carousel__item:nth-child(4) {
  background-image: url(../assets/images/banner/banner7-1.png);
}
.el-carousel__item:nth-child(5) {
  background-image: url(../assets/images/banner/banner4最新.png);
}
.el-carousel__item:nth-child(6) {
  background-image: url(../assets/images/banner/banner6-1.jpg);
}

img{
  /*设置图片宽度和浏览器宽度一致*/
      width:100%;
      height:inherit;
}




</style>

